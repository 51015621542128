import React, { useState } from "react";
import "./sidebarStyle.scss";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const treasury = sessionStorage.getItem("treasury")
  const hrPortal = sessionStorage.getItem("hrPortal")
  const crmPortal = sessionStorage.getItem("crmPortal")
  let fetchRole = sessionStorage.getItem("role")
  const role = fetchRole?.split(",")
  let sessions = sessionStorage.getItem("candidate")
  let session = JSON.parse(sessions)

  const handleDashboard = () => {
    const selectedDashboard = sessionStorage.getItem("selectedDashboard");
    if (selectedDashboard) {
      navigate(`${selectedDashboard}`);
    } else {
      console.error("selectedDashboard is not set.");
    }
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      sessionStorage.setItem("hide", "hide")
    } else {
      sessionStorage.removeItem("hide", "hide")
    }
  };
  const handleListingDashboard = () => {
    navigate("/listing-dashboard")
  }
  const handleManPowerRequisition = () => {
    navigate("/manpower-requisition-dashboard")
  }

  const handleRequisitionDashboard = () => {
    navigate('/approver-requisition-dashboard')
  }

  const handleHrPortal = () => {
    navigate('/hr-portal-dashboard')
  }

  const location = useLocation();
  const url = location?.pathname?.split("/")[1];

  let treasuryArr = ["Requestor", "Approver", "Treasury"]
  let ManpowerReqArray = ["Manager"]
  let ManPowerApprovalArray = ["HOD", "HR", "CHRO", "MD", "CEO", "Director", "CXO", "Segment HR"]
  let InterviewAppArray = ["Interviewer"]
  let InterviewApp = ["HR"]
  let crmArr = ["Inhouse consultant", "Outsourced consultant/Vendor", "Employee"]
  let crmApprover = ["L2", "L3"]


  return (
    <div className={url === "home" || url === "candidate-applicant-form" ? "sidebar-box" : "" || isSidebarOpen ? "sidebar-view hide" : "sidebar-view"}>
      <div className={isSidebarOpen ? "sidebar-view hide" : "sidebar-view"}>
        <div className="wrapper">
          <div className="siderbar-logo">
            <img src={require("../../assets/images/logo.png")} alt="Logo" />

          </div>
          <div className="hamburger" onClick={() => { toggleSidebar() }} >
            {isSidebarOpen ? <img className="hamburger_icon" src={require("../../assets/images/left.png")} alt="Logo" /> :
              <img onClick={() => { toggleSidebar() }} className="hamburger_icon" src={require("../../assets/images/right.png")} alt="Logo" />}
          </div>
          <nav id="sidebar">


            {treasury === "1" && <>
              <div className={url == "approver-journey-dashboard" ? "custom-nav-item active" : "custom-nav-item"} onClick={() => { handleDashboard() }} style={{ cursor: "pointer" }}>
                <img className="nav-icon" src={require("../../assets/images/dashboard.png")} alt="Dashboard Icon" />
                <span className="custom-nav-link" >Dashboard</span>
              </div>
              <div className={url == "listing-dashboard" ? "custom-nav-item active" : "custom-nav-item"} onClick={() => { handleListingDashboard() }} style={{ cursor: "pointer" }}>
                <img className="nav-icon" src={require("../../assets/images/dashboard.png")} alt="Dashboard Icon" />
                <span className="custom-nav-link" >Listing Data</span>
              </div>
            </>}



            {hrPortal == "1" &&
              <>
                {role.some(element => ManpowerReqArray.includes(element)) &&
                  <div className={url == "manpower-requisition-dashboard" || url == "view-requisition-form" || url == "add-new-requisition" || url == "edit-requisition-form" ? "custom-nav-item active" : "custom-nav-item"} onClick={() => { handleManPowerRequisition() }} style={{ cursor: "pointer" }}>
                    <img className="nav-icon" src={require("../../assets/images/businessman.png")} alt="Dashboard Icon" />
                    <span className="custom-nav-link" >Manpower Requisition</span>
                  </div>
                }

                {role.some(element => ManPowerApprovalArray.includes(element)) &&
                  <div className={url == "approver-requisition-dashboard" || url == "approver-requisition-form" ? "custom-nav-item active" : "custom-nav-item"} onClick={() => { handleRequisitionDashboard() }} style={{ cursor: "pointer" }}>
                    <img className="nav-icon" src={require("../../assets/images/businessman.png")} alt="Dashboard Icon" />
                    <span className="custom-nav-link" >Manpower Approval</span>
                  </div>}

                {role.some(element => InterviewApp.includes(element)) &&
                  <div className={url == "hr-portal-dashboard" || url == "schedule-applicant-interview" || url === "generate-applicant-link" ? "custom-nav-item active" : "custom-nav-item"} onClick={() => { handleHrPortal() }} style={{ cursor: "pointer" }}>
                    <img className="nav-icon" src={require("../../assets/images/hrPortal.png")} alt="Dashboard Icon" />
                    <span className="custom-nav-link" >Interview Application</span>
                  </div>
                }
                {role.some(element => InterviewAppArray.includes(element)) &&
                  <div className={url == "hr-portal-approver" || url == "interviwer-from" ? "custom-nav-item active" : "custom-nav-item"} onClick={() => navigate("/hr-portal-approver")} style={{ cursor: "pointer" }}>
                    <img className="nav-icon" src={require("../../assets/images/hrPortal.png")} alt="Dashboard Icon" />
                    <span className="custom-nav-link" >Interview</span>
                  </div>
                }
              </>
            }
            {crmPortal == "1" &&
              <>
                <div className={url == "Dashboard" || url == "add-employee" ? "custom-nav-item active" : "custom-nav-item"}>
                  <Link to="/Dashboard">
                    <img className="nav-icon" src={require("../../assets/images/dashboard.png")} alt="Dashboard Icon" />
                    <span className="custom-nav-link titleHeading" >User Dashboard</span>
                  </Link>
                </div>

                <div className={url == "raise-ticket" || url == 'add-ticket' || url == 'view-ticket' ? "custom-nav-item active" : "custom-nav-item"}>
                  <Link to="/raise-ticket">
                    <img className="nav-icon" src={require("../../assets/images/ticket.png")} alt="Dashboard Icon" />
                    {role.includes("Employee") && <span className="custom-nav-link" >My Raise Tickets</span>}
                    {role.includes("Inhouse consultant") && <span className="custom-nav-link" >Assigned Tickets</span>}
                    {role.includes("Outsourced consultant/Vendor") && <span className="custom-nav-link" >Assigned Tickets</span>}
                    {session?.support_levels?.some(element => crmApprover.includes(element)) && <span className="custom-nav-link" >Assigned Tickets</span>}
                  </Link>

                </div>

                {role.includes("Inhouse consultant") &&
                  <div className={url == "my-ticket" || url == "view-my-ticket" || url == "edit-ticket-end-user" ? "custom-nav-item active" : "custom-nav-item"}>
                    <Link to="/my-ticket">
                      <img className="nav-icon" src={require("../../assets/images/ticket.png")} alt="Dashboard Icon" />
                      <span className="custom-nav-link" >My Raise Tickets</span>
                    </Link>
                  </div>
                }

                <div className={url == "history-ticket"  ? "custom-nav-item active" : "custom-nav-item"}>
                  <Link to="/history-ticket">
                    <img className="nav-icon" src={require("../../assets/images/dashboard.png")} alt="Dashboard Icon" />
                    <span className="custom-nav-link " >Assigned History</span>
                  </Link>
                </div>
              </>
            }
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;


