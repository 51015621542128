import React, { useState, useEffect } from 'react'
import Header from '../../../component/Header'
import ReactPaginate from "react-paginate";
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import CustomCard from '../../../component/CustomeCard';
import CustomInput from '../../../component/CustomInput';
import CustomButton from '../../../component/Button/Button';
import Customselect from '../../../component/CustomSelect';
import RejectCandidateModal from '../../../component/HrNewModuleComponent/Modal/RejectCandidateModal';
import OnHoldCandidateModal from '../../../component/HrNewModuleComponent/Modal/OnHoldCandidate';
import ApproveCandidateModal from '../../../component/HrNewModuleComponent/Modal/ApproveCandidate';
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';
import ReactExport from "react-export-excel";
import FormatDate from '../../../component/helper/formatDate/formateDate';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
    {
        option: 10,
        value: 10,
    },
    {
        option: 100,
        value: 100,
    },
    {
        option: 500,
        value: 500,
    },
    {
        option: 1000,
        value: 1000,
    },
];

const InterviewerForm = () => {
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const { state } = useLocation();
    const navigate = useNavigate()
    const { crmStore } = useStores()
    const [isLoading, setIsLoading] = useState(false)
    const [selectApplicationDate, setSelectedApplicationDate] = useState(state?.item?.application_date?.split("T")[0])
    const [positionApplied, setPositionApplied] = useState(state?.item?.position_applied)
    const [departmentName, setDeparmentName] = useState(state?.item?.department)
    const [location, setLocation] = useState(state?.item?.location)
    const [fullName, setFullName] = useState(state?.item?.full_name)
    const [fatherName, setFatherName] = useState(state?.item?.father_name)
    const [EmailID, setEmailID] = useState(state?.item?.email_id)
    const [mobileNumber, setMobileNumber] = useState(state?.item?.contact)
    const [permanentAddress, setPermanentAddress] = useState(state?.item?.permanent_address)
    const [presentAddress, setPresentAddress] = useState(state?.item?.present_address)
    const [pinCode, setPinCode] = useState(state?.item?.pin_code_permanent)
    const [pinCodePresent, setPinCodePresent] = useState(state?.item?.pin_code_present)
    const [dateOfBirth, setDateOfBirth] = useState(state?.item?.date_of_birth?.split("T")[0])
    const [maritalStatus, setMaritalStatus] = useState(state?.item?.maritial_status)
    const [academicGap, setAcademicGap] = useState("")
    const [numberOfBackLog, setNubmerOfBacklog] = useState(state?.item?.candidateBacklogs[0]?.number_of_backlogs)
    const [totalExperience, setTotalExperience] = useState(state?.item?.total_experience)
    const [currentSalary, setCurrentSalary] = useState(state?.item?.current_salary)
    const [noticePeriod, setNoticePeriod] = useState(state?.item?.notice_period)
    const [expectedSalary, setExpectedSalary] = useState(state?.item?.expected_salary)
    const [expectedDateOfJoining, setExpectedDateOfJoining] = useState(state?.item?.expected_doj?.split("T")[0])
    const [educationalDetails, setEducationalDetails] = useState(state?.item?.candidateQualificationDetails ? state?.item?.candidateQualificationDetails : [])
    const [employmentDetails, setEmploymentDetails] = useState(state?.item?.candidateEmploymentDetails ? state?.item?.candidateEmploymentDetails : []);
    const [showApprovedModal, setShowApprovedModal] = useState(false)
    const [showRejectedModal, setShowRejectedModal] = useState(false)
    const [showOnHoldModal, setShowOnHoldmModal] = useState(false)
    const [isSelected, setIsSelected] = useState("")
    const [selectedInterviewer, setSelectedInterviewer] = useState("")
    const [leadership, setLeadership] = useState("")
    const [communication, setCommunication] = useState("")
    const [presentation, setPresentation] = useState("")
    const [managerial, setManagerial] = useState("")
    const [techKnowledge, setTechKnowledge] = useState("")
    const [remark, setRemark] = useState("")
    const [interviewList, setInterviewList] = useState([])
    const interviews = state?.item?.candidateInterview
    const lastInterview = interviews?.[interviews.length - 1];
    const candidateString = sessionStorage.getItem("candidate");
    const candidate = candidateString ? JSON.parse(candidateString) : null;

    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);


    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };

    // const dataToDisplay = showAll ? auditTrail : [auditTrail[auditTrail?.length - 1]]
    const dataToDisplay = showAll ? auditTrail : [auditTrail[0]]



    const getClassName = (status) => {
        switch (status) {
            case 'Pending':
                return 'circle-yellow';
            case 'Close':
                return 'circle-green';
            case 'Rejected':
                return 'circle-red';
            case 'Completed':
                return 'circle-green';
            case 'Hold':
                return 'circle-yellow';
            default:
                return '';
        }
    };

    const round = lastInterview?.round

    console.log(round, "mkkkkkkkkkkk");




    useEffect(() => {
        fetchInterviewer()
        fetchAuditTrail()

    }, [])
    const fetchAuditTrail = async () => {
        await crmStore.getAuditInterview(state?.item?.candidate_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data?.candidateAuditTrailRecords)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }



    const fetchInterviewer = async () => {
        await crmStore.interviewer((status, res) => {
            if (res?.status === "success") {
                setInterviewList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }


    let options = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    useEffect(() => {
        if (state?.item?.candidateBacklogs[0]?.academic_gap === true) {
            setAcademicGap("Yes");
        } else if (state?.item?.candidateBacklogs[0]?.academic_gap === false) {
            setAcademicGap("No");
        } else {
            setAcademicGap("");
        }
    }, [state]);



    const handleOnHold = async () => {
        let payload = {
            technical_knowledge: techKnowledge,
            managerial_skills: managerial,
            communication_skills: communication,
            presentation: presentation,
            leadership_skills: leadership,
            remarks: remark,
            is_approved: false,
            is_rejected: false,
            on_hold: true,
            is_next_round: false,
            interviewer_id: candidate?.id,
            // is_interview_complete:false
        }
        setIsLoading(true)
        await crmStore.InterviewStatus(state?.item?.candidate_id, payload, (res) => {
            if (res?.data?.status === "success") {
                notify(res?.data?.message)
                setIsLoading(false)
                setShowOnHoldmModal(true)
            } else {
                setIsLoading(false)
                notify(res?.data?.message, "danger")
                setShowOnHoldmModal(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setShowOnHoldmModal(false)
        })
    }

    const handleApprovedModal = async () => {
        if (isSelected == "" || isSelected == "NA" || isSelected == "undefined" || isSelected == null) {
            return notify("please select one options", "danger")
        }
        if (isSelected == "Yes" && selectedInterviewer == "") {
            return notify("please select one interviewer", "danger")
        }
        let payload = {
            technical_knowledge: techKnowledge,
            managerial_skills: managerial,
            communication_skills: communication,
            presentation: presentation,
            leadership_skills: leadership,
            remarks: remark,
            is_approved: true,
            is_rejected: false,
            on_hold: false,
            is_next_round: isSelected == "Yes" ? true : false,
            interviewer_id: candidate?.id,
            // is_interview_complete:false
            // next_interviewer_id : selectedInterviewer  
        }
        if (selectedInterviewer) {
            payload.next_interviewer_id = selectedInterviewer
        }
        setIsLoading(true)
        await crmStore.InterviewStatus(state?.item?.candidate_id, payload, (res) => {
            if (res?.data?.status === "success") {
                notify(res?.data?.message)
                setIsLoading(false)
                setShowApprovedModal(false)
                navigate("/hr-portal-approver")
            } else {
                setIsLoading(false)
                notify(res?.data?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handleApproved = () => {
        setShowApprovedModal(true)
    }

    const handleRejected = async () => {
        let payload = {
            technical_knowledge: techKnowledge,
            managerial_skills: managerial,
            communication_skills: communication,
            presentation: presentation,
            leadership_skills: leadership,
            remarks: remark,
            is_approved: false,
            is_rejected: true,
            on_hold: false,
            is_next_round: false,
            interviewer_id: candidate?.id,
            // is_interview_complete:false
        }
        setIsLoading(true)
        await crmStore.InterviewStatus(state?.item?.candidate_id, payload, (res) => {
            if (res?.data?.status === "success") {
                notify(res?.data?.message)
                setIsLoading(false)
                setShowRejectedModal(true)
            } else {
                setIsLoading(false)
                notify(res?.data?.message, "danger")
                setShowRejectedModal(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setShowRejectedModal(false)
        })
    }


    let reviewList = [
        { id: "Exceptional", name: "Exceptional" },
        { id: "Good", name: "Good" },
        { id: "Average", name: "Average" },
        { id: "Not Suitable", name: "Not Suitable" },
    ]

    function   getOrdinalSuffix(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = number % 100;
        return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
      }

    return (
        <div className="main-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Interviewer Form,"} />
                <div className='row'>
                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Personal"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <>
                                    <img src={require("../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select Application Date"
                                                require={true}
                                                placeholder='Select Application Date'
                                                type='date'
                                                readOnly={true}
                                                value={selectApplicationDate}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Position Applied"
                                                require={true}
                                                placeholder='Position Applied'
                                                readOnly={true}
                                                value={positionApplied}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Department Name"
                                                require={true}
                                                placeholder='Department Name'
                                                readOnly={true}
                                                value={departmentName}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Location"
                                                require={true}
                                                placeholder='Location'
                                                readOnly={true}
                                                value={location}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Full Name"
                                                require={true}
                                                placeholder='Full Name'
                                                readOnly={true}
                                                value={fullName}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Father’s Name"
                                                require={true}
                                                placeholder='Father’s Name'
                                                readOnly={true}
                                                value={fatherName}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Mobile Number"
                                                require={true}
                                                placeholder='Mobile Number'
                                                readOnly={true}
                                                value={mobileNumber}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="E-mail ID"
                                                require={true}
                                                placeholder='E-mail ID'
                                                readOnly={true}
                                                value={EmailID}
                                            />
                                        </div>
                                        <div className='col-md-8'>
                                            <CustomInput
                                                label="Permanent Address"
                                                require={true}
                                                placeholder='Permanent Address'
                                                readOnly={true}
                                                value={permanentAddress}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <CustomInput
                                                label="Permanent Pincode"
                                                require={true}
                                                placeholder='Permanent Pincode'
                                                readOnly={true}
                                                value={pinCode}
                                            />
                                        </div>
                                        <div className='col-md-8'>
                                            <CustomInput
                                                label="Present Address"
                                                require={true}
                                                placeholder='Present Address'
                                                readOnly={true}
                                                value={presentAddress}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <CustomInput
                                                label="Present Pincode"
                                                require={true}
                                                placeholder='Present Pincode'
                                                readOnly={true}
                                                value={pinCodePresent}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="DOB"
                                                require={true}
                                                type="date"
                                                placeholder='DOB'
                                                readOnly={true}
                                                value={dateOfBirth}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Marital Status"
                                                require={true}
                                                placeholder='Marital Status'
                                                readOnly={true}
                                                value={maritalStatus}
                                            />
                                        </div>
                                    </div>

                                </>}
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Educational"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    {educationalDetails?.map((item, index) => {
                                        let data = item?.class !== "Post Graduation" ? true : false
                                        return (
                                            <>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Class Name"
                                                        require={data}
                                                        value={item?.class}
                                                        readOnly={true}
                                                        placeholder="Select Class Name"
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name of Course"
                                                        require={data}
                                                        placeholder='Name of Course'
                                                        value={item?.course_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name of the Institute"
                                                        require={data}
                                                        placeholder='School/College/Institute Name'
                                                        value={item?.school_college_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Board/University Name"
                                                        require={data}
                                                        placeholder='Board/University Name'
                                                        value={item?.board_university}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Regular/Part-time"
                                                        require={data}
                                                        value={item?.education_type}
                                                        readOnly={true}
                                                        placeholder="Select Regular/Part-time"
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Year of Passing"
                                                        require={data}
                                                        placeholder='Select Year of Passing'
                                                        type="date"
                                                        value={item?.year_of_passing?.split("T")[0]}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Percentage(%)/Grade"
                                                        require={data}
                                                        placeholder='Percentage(%)/Grade'
                                                        value={item?.percentage_grade}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className='col-md-3'>

                                                </div>
                                            </>
                                        )
                                    })}


                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='radio-type'>
                                                <h3>Academic Gap<span style={{ color: "red" }}>*</span></h3>
                                                <div className=' academic-box'>
                                                    <div className='radio-btnn' >
                                                        <input type='radio' name='Academic Gap' readOnly={true} value="Yes" checked={academicGap === "Yes"} />
                                                        <label >Yes</label>
                                                    </div>
                                                    <div className='radio-btnn'>
                                                        <input type='radio' name='Academic Gap' readOnly={true} value="No" checked={academicGap === "No"} />
                                                        <label >No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Number of Backlogs, if any"
                                                require={true}
                                                placeholder='Number of Backlogs, if any'
                                                readOnly={true}
                                                value={numberOfBackLog}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    {employmentDetails.map((detail, index) => (
                        <div key={index} className='col-md-12'>
                            <CustomCard
                                cardtitle={"Employment"}
                                cardboldtitle={`Details ${index + 1}`}
                                cardbody={
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Organization Name"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Organization Name'
                                                value={detail.organization}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Designation Name"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Designation Name'
                                                value={detail.designation}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select From (Month, year)"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Select From (Month, year)'
                                                type="date"
                                                value={detail.select_from_date?.split("T")[0]}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select To (Month, year)"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Select To (Month, year)'
                                                type="date"
                                                value={detail.select_to_date?.split("T")[0]}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Current CTC"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Current CTC'
                                                value={detail.ctc}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Reason for Leaving"
                                                require={true}
                                                readOnly={true}
                                                placeholder='Reason for Leaving'
                                                value={detail.reason_for_leaving}
                                            />
                                        </div>

                                    </div>
                                }
                            />
                        </div>
                    ))}

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Other"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Total Experience"
                                            require={true}
                                            placeholder='Total Experience'
                                            readOnly={true}
                                            value={totalExperience}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Current Salary"
                                            require={true}
                                            placeholder='Current Salary'
                                            readOnly={true}
                                            value={currentSalary}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Notice Period"
                                            require={true}
                                            placeholder='Notice Period'
                                            type="date"
                                            readOnly={true}
                                            value={noticePeriod}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expected Salary"
                                            require={true}
                                            placeholder='Expected Salary'
                                            readOnly={true}
                                            value={expectedSalary}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expected DOJ"
                                            require={true}
                                            type="date"
                                            placeholder='Expected DOJ'
                                            readOnly={true}
                                            value={expectedDateOfJoining}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Interview"}
                            cardboldtitle={"Feedback"}
                            cardbody={
                                <div className='row'>
                                    {lastInterview?.is_interview_complete === true ?
                                        <>
                                            {interviews?.map((item) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='col-md-3'>
                                                            <CustomInput
                                                                label="Job/Technical Knowledge"
                                                                placeholder="Job/Technical Knowledge"
                                                                require={true}
                                                                value={item?.technical_knowledge}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <CustomInput
                                                                label="Managerial Skills"
                                                                placeholder="Managerial Skills"
                                                                require={true}
                                                                value={item?.managerial_skills}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <CustomInput
                                                                label="Communication Skills"
                                                                placeholder="Communication Skills"
                                                                require={true}
                                                                value={item?.communication_skills}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <CustomInput
                                                                label="Presentation/Confidence"
                                                                placeholder="Presentation/Confidence"
                                                                require={true}
                                                                value={item?.presentation}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <CustomInput
                                                                label="Leadership Skills"
                                                                placeholder="Leadership Skills"
                                                                require={true}
                                                                value={item?.leadership_skills}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <CustomInput
                                                                label="Remarks(If any)"
                                                                require={true}
                                                                placeholder="Remarks(If any)"
                                                                value={item?.remarks}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </React.Fragment>)
                                            })}
                                        </>
                                        :
                                        <>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Job/Technical Knowledge"
                                                    require={true}
                                                    value={techKnowledge}
                                                    onChange={(e) => {
                                                        setTechKnowledge(e.target.value);
                                                    }}
                                                    optionview={reviewList?.map((option) => {
                                                        return (
                                                            <option value={option?.id}>{option?.name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Managerial Skills"
                                                    require={true}
                                                    value={managerial}
                                                    onChange={(e) => {
                                                        setManagerial(e.target.value);
                                                    }}
                                                    optionview={reviewList?.map((option) => {
                                                        return (
                                                            <option value={option?.id}>{option?.name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Communication Skills"
                                                    require={true}
                                                    value={communication}
                                                    onChange={(e) => {
                                                        setCommunication(e.target.value);
                                                    }}
                                                    optionview={reviewList?.map((option) => {
                                                        return (
                                                            <option value={option?.id}>{option?.name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Presentation/Confidence"
                                                    require={true}
                                                    value={presentation}
                                                    onChange={(e) => {
                                                        setPresentation(e.target.value);
                                                    }}
                                                    optionview={reviewList?.map((option) => {
                                                        return (
                                                            <option value={option?.id}>{option?.name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Customselect
                                                    label="Leadership Skills"
                                                    require={true}
                                                    value={leadership}
                                                    onChange={(e) => {
                                                        setLeadership(e.target.value);
                                                    }}
                                                    optionview={reviewList?.map((option) => {
                                                        return (
                                                            <option value={option?.id}>{option?.name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-8'>
                                                <CustomInput
                                                    label="Remarks(If any)"
                                                    require={true}
                                                    placeholder="Remarks(If any)"
                                                    value={remark}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                />
                                            </div>
                                        </>}
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <>
                                    <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                    <button className="export-btn">
                                        <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../assets/images/excel.png")} />Export</div>}>
                                            <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                ...item, index: i + 1, role: item?.role,
                                                created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                            }))} name="Sheet">
                                                <ExcelColumn label="Status Date & Time" value="created_at" />
                                                <ExcelColumn label="Name" value="userName" />
                                                <ExcelColumn label="Role" value="role" />
                                                <ExcelColumn label="Action Required" value="status" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </button>
                                </>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th className="manpower-table">Status Date & Time</th>
                                                    <th className="manpower-table">Candidate Name</th>
                                                    <th className="manpower-table">Interviewer Name</th>
                                                    <th className="manpower-table">Application Status</th>
                                                    <th className="manpower-table">Round</th>
                                                    <th className="manpower-table">Remark</th>
                                                </tr>
                                            </thead>
                                            {dataToDisplay?.length > 0 && (
                                                <tbody>
                                                    {dataToDisplay?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                <td>{item?.candidate_name}</td>
                                                                <td>{item?.interviewer_name}</td>
                                                                {/* <td>{item?.is_application_status}</td> */}
                                                                <td>
                                                                    <div className="circle-box">
                                                                        <span className={getClassName(item?.is_application_status)}></span> {item?.is_application_status}
                                                                    </div>
                                                                </td>
                                                                <td>{item?.round}</td>
                                                                <td>{item?.remark}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>


                    {lastInterview?.is_interview_complete !== true &&
                        <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejected} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid me-3"} onClick={handleApproved} />
                            <CustomButton children={"On Hold"} style={"btn--onHold--solid"} onClick={handleOnHold} />
                        </div>
                    }


                </div>
            </div>
            <RejectCandidateModal
                show={showRejectedModal}
                hide={() => {
                    setShowRejectedModal(false)
                    navigate("/hr-portal-approver")
                }}
                title="You have rejected the candidate"
            />
            <OnHoldCandidateModal
                show={showOnHoldModal}
                hide={() => {
                    setShowOnHoldmModal(false)
                    navigate("/hr-portal-approver")
                }}
                title="You have on hold the candidate"
            />
            <ApproveCandidateModal
                show={showApprovedModal}
                hide={() => {
                    setShowApprovedModal(false)
                    setSelectedInterviewer("")
                    setIsSelected("")
                }}
                title="You have approved candidate"
                title1={`Do you want ${getOrdinalSuffix((Number(round)) + 1)} round?`}
                options={options}
                handleSelect={(e) => setIsSelected(e)}
                isSelected={isSelected}
                handleSelectedInterviewer={(e) => setSelectedInterviewer(e)}
                interviewList={interviewList}
                selectedInterviewer={selectedInterviewer}
                handleApprovedModal={handleApprovedModal}
            />
        </div>
    )
}

export default InterviewerForm